.tag {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    appearance: none;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
    white-space: nowrap;
    color: black;
}

.tag.active {
    border: 1px solid rgb(255, 208, 79);
    background-color: rgb(255, 208, 79);
}

.tag.active i {
    padding-left: 8px;
}

/* .tagContainer button {
    display: flex;
    padding: 6px;
    padding-right: 0;
    font-size: 0.9em;
    margin-left: 3px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: black;
} */