.container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black;
}

.container input {
  min-width: 30%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
  width: auto;
}

.container input:focus {
    box-shadow: none;
}

.container:focus-within {
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.container[disabled] {
    background-color: #e9ecef;
    opacity: 1;
}

.tagContainer {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  border: 1px solid var(--color-annotation-comment);
  border-radius: 5px;
  background-color: var(--color-annotation-comment);
  white-space: nowrap;
  color: black;
}

.tagContainer button {
  appearance: none;
  display: flex;
  padding: 6px;
  padding-right: 0;
  font-size: 0.9em;
  margin-left: 3px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: black;
}